import React, { FC } from "react";
import { Pagination } from "react-bootstrap";
import { Pages } from "domains/common";

type Props = {
  pages: Pages;
  setPage: (page: number) => void;
  prefix?: string;
};

const Paginate: FC<Props> = ({ pages, setPage, prefix }) => {
  const { current, last, prev, next, total } = pages;

  const pageNumbers = [...Array(5)]
    .map((_, i) => {
      const p = current + i;
      if (p > last) return null;

      return p;
    })
    .filter((i) => i) as number[]; //null除去

  if (last === 1) return <></>;

  return (
    <div className="d-flex justify-content-center">
      <Pagination>
        {prev && (
          <>
            <Pagination.First onClick={() => setPage(1)} />
            <Pagination.Prev onClick={() => setPage(prev)} />
          </>
        )}

        {pageNumbers.map((page) => (
          <Pagination.Item
            key={`${prefix}_${page}`}
            active={page === current}
            onClick={() => setPage(page)}
          >
            {page}
          </Pagination.Item>
        ))}

        {next && (
          <>
            <Pagination.Next onClick={() => setPage(next)} />
            <Pagination.Last onClick={() => setPage(last)} />
          </>
        )}
      </Pagination>
    </div>
  );
};

export default Paginate;
