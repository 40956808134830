import React, { FC } from "react";
import Link from "next/link";
import Emission, { EmissionRepository } from "domains/Emission";
import Category from "domains/Category";
import { Table, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { commas } from "utils/Helper";
import useAppContext from "hooks/useAppContext";

type Props = {
  items: Emission[];
  showDetailButton?: boolean;
  categories?: Category[];
  receive?: boolean;
};

const EmissionDetailTable: FC<Props> = ({
  items,
  showDetailButton,
  categories,
  receive,
}) => {
  const { setLoad } = useAppContext();
  const changeCategory = async (categoryID: string, i: number, j: number) => {
    const item = items[i];
    const { id } = item;
    setLoad(true);
    const res = await EmissionRepository.updateCategory(id, j, categoryID);
    setLoad(false);
    if ("item" in res) return toast.success("完了しました");

    toast.error(res.error?.message || "不明なエラー");
  };

  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {!receive && <th className="mnw-100">作成者</th>}
            <th className="mnw-100">{!receive ? "通知先" : "通知元"}</th>
            {receive && <th className="mnw-100">作成者</th>}
            <th className="mnw-100">件名</th>
            <th className="mnw-100">管理番号</th>
            <th className="mnw-100">受渡日</th>
            <th className="mnw-100">作成日</th>
            <th className="mnw-100">通知日</th>
            <th className="mnw-100">ステータス</th>
            <th className="mnw-100">活動内容</th>
            <th className="mnw-100">活動量</th>
            <th className="mnw-100">単位</th>
            <th className="mnw-100">排出原単位</th>
            <th className="mnw-100">排出量</th>
            {categories && !!categories.length && (
              <th className="mnw-100">カテゴリ設定</th>
            )}
            {showDetailButton && <th className="mnw-100">確認</th>}
          </tr>
        </thead>
        <tbody>
          {items.map((item, i) => {
            return (
              item.activities &&
              item.activities.map((act, j) => {
                return (
                  <tr key={`table-emission-${item.id}-${j}`}>
                    {receive && <td>{item.company?.name}</td>}
                    <td>
                      {item.user?.displayName}
                      <br />
                      <h6 className="ps-2 mb-0 small text-muted">{item.id}</h6>
                    </td>
                    {!receive && <td>{item.receiverCompany?.name}</td>}
                    <td>{item.title}</td>
                    <td>{item.manageNo}</td>
                    <td>{item.transactionedAtFormatDate()}</td>
                    <td>{item.createdAtFormatDate()}</td>
                    <td>{item.notifiedAtFormatDate()}</td>
                    <td>
                      {!receive ? item.statusJa() : item.statusReceiveJa()}
                    </td>
                    <td>{act.name || "-"}</td>
                    <td>{commas(act.volume) || "-"}</td>
                    <td>{act.unit || "-"}</td>
                    <td>{commas(act.intensity) || "-"}</td>
                    <td>{commas(act.volume * act.intensity)} t</td>
                    {categories && !!categories.length && (
                      <td>
                        <Form.Select
                          aria-label="カテゴリ"
                          defaultValue={act.categoryID}
                          size="sm"
                          onChange={(e) => changeCategory(e.target.value, i, j)}
                        >
                          <option value={""}>未設定</option>
                          {categories.map((category) => {
                            return (
                              <option
                                value={category.id}
                                key={`table-emission-${item.id}-${j}-option-${category.id}`}
                              >
                                {category.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </td>
                    )}
                    {showDetailButton && (
                      <td>
                        <Link href={`./${item.id}`} passHref>
                          <Button>確認</Button>
                        </Link>
                      </td>
                    )}
                  </tr>
                );
              })
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default EmissionDetailTable;
