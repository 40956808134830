import React, { FC, useState, useEffect, useCallback } from "react";
import Emission from "domains/Emission";

type Props = {
  emission: Emission;
};

const CalcEmissionVolume: FC<Props> = ({ emission }) => {
  const [sum, setSum] = useState<string>("0");

  useEffect(() => {
    const calcSum = async () => {
      setSum(await emission.calcEmissionVolume());
    };
    calcSum();
  }, [emission]);

  return <>{sum}</>;
};

export default CalcEmissionVolume;
