import React, { FC } from "react";
import Link from "next/link";
import Emission, { EmissionRepository } from "domains/Emission";
import Category from "domains/Category";
import { Table, Button } from "react-bootstrap";
import CalcEmissionVolume from "components/emission/CalcEmissionVolume";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "next/router";

type Props = {
  items: Emission[];
  detailLink?: (id: string) => {};
  categories?: Category[];
  showArchiveButton?: boolean;
  receive?: true;
};

const EmissionTable: FC<Props> = ({
  items,
  detailLink,
  categories,
  showArchiveButton,
  receive,
}) => {
  const router = useRouter();
  const toggelArchive = async (id: string, currentArchive: boolean) => {
    const { archiveReceiver, archiveSender } = EmissionRepository;
    const action = receive ? archiveReceiver : archiveSender;
    const res = await action(id, !currentArchive);
    if ("item" in res) {
      toast.success("完了しました。画面を更新します。");
      setTimeout(() => {
        router.reload();
      }, 2000);
      return;
    }

    toast.error(res.error?.message || "不明なエラー");
  };

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          {!receive && <th>作成者</th>}
          <th className="mnw-200">{!receive ? "通知先" : "通知元"}</th>
          {receive && <th className="mnw-100">作成者</th>}
          <th className="mnw-200">件名</th>
          <th>管理番号</th>
          <th className="mnw-100">受渡日</th>
          <th className="mnw-100">作成日</th>
          <th className="mnw-100">通知日</th>
          <th className="mnw-80">ステータス</th>
          <th className="mnw-100">排出量</th>
          {categories && !!categories.length && (
            <th className="mnw-100">カテゴリ設定</th>
          )}
          {(detailLink || showArchiveButton) && (
            <th className="mnw-100">アクション</th>
          )}
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          return (
            <tr key={`table-emission-${item.id}-${index}`}>
              {receive && <td>{item.company ? item.company.name : ""}</td>}
              <td className="mxw-120">
                {item.user ? item.user.displayName : ""}
                <br />
                <h6
                  className="ps-2 mb-0 small text-muted text-truncate"
                  title={item.id}
                >
                  {item.id}
                </h6>
              </td>
              {!receive && (
                <td>
                  {item.receiverCompany ? (
                    item.receiverCompany.name
                  ) : (
                    <>
                      {item.newCompanyName}
                      <br />
                      <small className="text-muted">
                        {item.newCompanyEmail}
                      </small>
                    </>
                  )}
                </td>
              )}
              <td className="mnw-110">{item.title}</td>
              <td>{item.manageNo}</td>
              <td>{item.transactionedAtFormatDate()}</td>
              <td>{item.createdAtFormatDate()}</td>
              <td>{item.notifiedAtFormatDate()}</td>
              <td className="mnw-120">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-success me-1"
                />
                {!receive ? item.statusJa() : item.statusReceiveJa()}
              </td>
              <td>
                <CalcEmissionVolume emission={item} />
              </td>
              {detailLink && (
                <td className="mnw-120">
                  <Link href={detailLink(item.id)} passHref>
                    <Button>確認</Button>
                  </Link>
                  {showArchiveButton && (
                    <Button
                      size="sm"
                      variant="link"
                      onClick={() => {
                        toggelArchive(
                          item.id,
                          receive ? item.archiveReceiver : item.archiveSender
                        );
                      }}
                    >
                      <small>アーカイブ</small>
                    </Button>
                  )}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default EmissionTable;
